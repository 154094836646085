<template>
	<div class="o-wrap">
		<el-page-header @back="$router.go(-1)" content="自定义分类" />
		<div class="cf">
			<p>小提示：</p>
			<p>1. 若您未设置任何分类，店铺将会按照产品所属行业自动分类；如果您设置了分类，店铺将会按照您设置的分类显示。</p>
			<p>2. 只有当分类中有产品，店铺中才会显示此分类；如果分类里没有产品，店铺将不显示此分类。</p>
			<p>3. 每个一级分类下最多可添加10个子分类，分类名称最多15个字。</p>
		</div>
		<div class="nav-wrap">
			<div class="nav-th">
				<div calss="nav-th-item">分类名称</div>
				<div calss="nav-th-item">排序</div>
				<div calss="nav-th-item">操作</div>
			</div>
			<div class="nav-td-wrap" v-for="(item,index) in list" :key="item.spm">
				<div class="nav-td">
					<div class="nav-td-item">
						<input type="text" v-model="item.catname">
					</div>
					<div class="nav-td-item">
						<!-- <i class="el-icon-caret-top" v-if="item.down" @click="handleUp(index)" />
						<i class="el-icon-caret-top icon-fc" v-else />
						<i class="el-icon-caret-bottom" v-if="item.up" @click="handleDown(index)" />
						<i class="el-icon-caret-bottom icon-fc" v-else /> -->
					</div>
					<div class="nav-td-item">
						<el-button type="info" v-if="!item.child||!item.child.length" icon="el-icon-delete" circle
							size="mini" @click="handleDel(index)" />
						<el-button type="warning" icon="el-icon-plus" circle size="mini"
							@click="handleAddChild(index)" />
					</div>
				</div>
				<div class="nav-td" v-for="(items,indexs) in item.child" :key="items.spm">
					<div class="nav-td-item">
						<input type="text" class="child-text" v-model="items.catname">
					</div>
					<div class="nav-td-item">
						<!-- <i class="el-icon-caret-top" v-if="items.down" @click="handleUpChild(index,indexs)" />
						<i class="el-icon-caret-top icon-fc" v-else />
						<i class="el-icon-caret-bottom" v-if="items.up" @click="handleDownChild(index,indexs)" />
						<i class="el-icon-caret-bottom icon-fc" v-else /> -->
					</div>
					<div class="nav-td-item">
						<el-button type="info" icon="el-icon-delete" circle size="mini"
							@click="handleDelChild(index,indexs)" />
					</div>
				</div>
			</div>
		</div>
		<div class="empty" v-if="!list.length">暂无分类</div>
		<div class="nav-bottom">
			<el-button type="primary" icon="el-icon-plus" @click="handleAdd" size="small">添加分类</el-button>
			<el-button type="primary" size="small"  @click="handleSubmit">提交</el-button>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				list: []
			}
		},
		created() {
			this.$axios.post('/getsellercat').then(data => {
				this.list = data
			})
		},
		methods: {
			handleAdd() {
				this.list.push({
					catname: '',
					child: [],
					id: '',
					parentid: '0',
					spm: Date.now()
				})
			},
			handleDel(index) {
				this.$confirm('此操作将永久删除该分类, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					let id = this.list[index].id
					if (id) {
						this.$axios.post('/delSellerCat', {
							id
						}).then(data => {
							location.reload()
						})
					} else {
						this.list.splice(index, 1)
					}
				}).catch(() => {});
			},
			handleAddChild(index) {
				let list = this.list
				if (list[index].child == undefined) {
					this.$set(list[index], 'child', [])
				}
				list[index].child.push({
					catname: '',
					id: '',
					parentid: list[index].id,
					spm: Date.now()
				})
				this.list = list
			},
			handleUp(index) {
				this.list[index] = this.list.splice(index - 1, 1, this.list[index])[0]
			},
			handleDown(index) {
				this.list[index] = this.list.splice(index + 1, 1, this.list[index])[0]
			},
			handleDelChild(index, indexs) {
				this.$confirm('此操作将永久删除该分类, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					let id = this.list[index].child[indexs].id
					if (id) {
						this.$axios.post('/delSellerCat', {
							id
						}).then(data => {
							location.reload()
						})
					} else {
						this.list[index].child.splice(indexs, 1)
					}
				}).catch(() => {})
			},
			handleUpChild(index, indexs) {
				this.list[index].child[indexs] = this.list[index].child.splice(indexs - 1, 1, this.list[index].child[
					indexs])[0]
			},
			handleDownChild(index, indexs) {
				this.list[index].child[indexs] = this.list[index].child.splice(indexs + 1, 1, this.list[index].child[
					indexs])[0]
			},
			handleSubmit() {
				this.$axios.post('/setsellercat', {
					data: this.list
				}).then(data => {
					this.$message({
						message: '修改成功',
						type: 'success'
					});
				})
			}
		},
		computed: {
			newList() {
				// this.list.forEach((item, index) => {
				// 	item.up = this.list[index + 1] ? true : false
				// 	item.down = this.list[index - 1] ? true : false
				// 	item.child.forEach((items, indexs) => {
				// 		items.up = item.child[indexs + 1] ? true : false
				// 		items.down = item.child[indexs - 1] ? true : false
				// 	})
				// })
				// return this.list
				return this.list
			}
		}
	}
</script>

<style scoped>
	.o-wrap{
		width: 1600px;
		background-color: #fff;
	}
	.cf {
		width: 1520px;
		margin: 0 auto;
		background: #f8f8f8;
		padding: 10px 20px;
		color: rgba(0, 0, 0, .6);
		font-size: 12px;
		line-height: 22px;
		border-radius: 8px;
	}

	.cf:hover {
		box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
	}

	.nav-wrap {
		width: 750px;
		margin-top: 20px;
		margin-left: 20px;
		border-bottom: 1px solid #d6d6d6;
	}

	.nav-th {
		display: flex;
		border-bottom: 1px solid #d6d6d6;
		height: 40px;
		line-height: 35px;
		background: #f3f3f3;
	}

	.nav-th>div,
	.nav-td>div {
		padding-left: 40px;
		display: flex;
		/* justify-content: center; */
	}

	.nav-th>div,
	.nav-td>div {
		width: 250px;
	}

	.nav-td {
		display: flex;
		height: 42px;
		align-items: center;
		margin: 5px 0;
	}

	.nav-td input {
		border: 1px solid #ccc;
		width: 150px;
		height: 18px;
		font-size: 14px;
		text-indent: 5px;
	}

	.el-icon-caret-top,
	.el-icon-caret-bottom {
		cursor: pointer;
		font-size: 25px;
		color: #5FB878;
	}

	.icon-fc {
		color: #ccc;
	}

	.child-text {
		margin-left: 50px;
	}

	.nav-bottom {
		display: flex;
		justify-content: space-between;
		margin-top: 20px;
		width: 750px;
		margin-left: 20px;
	}
	
	.empty{
		width: 750px;
		text-align: center;
		margin: 100px 0;
		font-size: 14px;
	}
</style>
